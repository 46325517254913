<script>
/* eslint-disable vue/require-prop-type-constructor */
/* eslint-disable @typescript-eslint/camelcase */
import resize from './mixins/resize'
export default {
  mixins: [resize],
  data() {
    return {
      chart: null
    }
  },
  props: {
    option: {
      type: Object,
      required: false
    },
    theme: {
      type: Object | String,
      required: false
    },
    init_option: {
      type: Object,
      required: false
    },
    id: {
      type: String,
      required: false,
      default() {
        return `echart_${this._uid}`
      }
    },
    auto_load: {
      type: Boolean,
      required: false,
      default: true
    },
    parentId: {
      type: String,
      required: false,
      default: ''
    }
  },
  activated() {
    if (this.auto_load) {
      this.load()
    }
  },
  methods: {
    load() {
      if (this.chart) {
        this.unload()
      }
      if (document.getElementById(this.id) !== null) {
        if (!this.chart) {
          this.chart = echarts.init(document.getElementById(this.id), this.theme, this.init_option)
        }
        this.chart.setOption(this.option, /* notMerge */ true)
        this.chart.on('click', params => {
          if (typeof params.seriesIndex === 'undefined') {
            return
          }
          if (
            params.type === 'click' &&
            params.seriesType === 'graph' &&
            params.dataType === 'node'
          ) {
            this.$emit('clickNode', { id: params.data.id, tags: params.data.tags })
          }
        })
      }
    },
    unload() {
      if (this.chart) {
        this.chart.dispose()
      }
      this.chart = null
    }
  },
  watch: {
    option() {
      if (this.auto_load) {
        this.load()
      }
    }
  },
  deactivated() {
    this.unload()
  },
  beforeDestroy() {
    this.unload()
  }
}
</script>
<template>
  <div :id="id" class="dg-echart"></div>
</template>
<style lang="scss">
.dg-echart {
  height: 100%;
  width: 100%;
}
</style>
