<script>
import ECharts from '@/components/common/Echarts/echart'
import { variables } from '@/libs/theme'
export default {
  data() {
    return {
      option: {}
    }
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    parentId: {
      type: String,
      required: true
    }
  },
  activated() {
    //this.update();
  },
  methods: {
    update() {
      const option = {
        tooltip: {
          trigger: 'item',
          backgroundColor: [variables.backgroundColorPopper],
          extraCssText: `box-shadow:0px 0px 10px 0px ${variables.backgroundColorPopper};`,
          formatter: function (params) {
            const res =
              `<div style="font-size:16px;color:${variables.textColorRegular};padding:8px;"><p style="border-bottom:1px solid ${variables.backgroundColorMenu};text-align:center">` +
              params.name +
              `数</p><p>人数<span style="color:${variables.colorPrimary};margin-left:10px">` +
              params.value +
              `</span></p><p>比例<span style="color:${variables.colorPrimary};margin-left:10px">` +
              params.data.percent +
              '%</span></p></div>'
            return res
          }
        },
        title: {
          text: '客户总数',
          left: 'center',
          top: '42%',
          textStyle: {
            color: [variables.textColorRegular],
            fontWeight: 'normal',
            fontSize: 18,
            align: 'center'
          }
        },
        color: [[variables.colorPrimary], [variables.echartLine3Color]],
        legend: {
          selectedMode: false,
          formatter: () => {
            let total = 0
            this.value.forEach(function (value) {
              total += value.value
            })
            return total
          },
          data: [this.value[0].name],
          left: 'center',
          top: '50%',
          icon: 'none',
          align: 'center',
          padding: [10, 0],
          textStyle: {
            color: [variables.textColorRegular],
            fontSize: 24
          }
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['50%', '70%'],
            center: ['50%', '50%'],
            //avoidLabelOverlap: true,
            hoverAnimation: true,
            itemStyle: {
              emphasis: {
                shadowBlur: 14,
                shadowOffsetX: 0,
                shadowColor: [variables.backgroundColorPopper]
              },
              normal: {
                // shadowBlur: 12,
                shadowOffsetX: 0,
                shadowColor: [variables.grey73Color]
              }
            },
            label: {
              //在扇形上显示内容
              normal: {
                formatter: function (params) {
                  return params.data.percent + '%'
                },
                padding: [0, 4],
                rich: {
                  d: {
                    fontSize: 13
                  }
                }
              }
            },
            data: this.value
          }
        ]
      }
      this.option = option
    }
  },
  watch: {
    value() {
      this.update()
    }
  },
  components: {
    ECharts
  }
}
</script>
<style></style>
<template>
  <div>
    <e-charts :option="option" :auto_load="true" :parentId="parentId"></e-charts>
    <slot />
  </div>
</template>
