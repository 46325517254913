
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import ECharts from '@/components/common/Echarts/echart.vue'
import { variables } from '@/libs/theme'

@Component({
  name: 'barGraph',
  components: {
    ECharts
  }
})
export default class BarGraph extends Vue {
  @Prop({}) private name: string[]
  @Prop({}) private value: number[]
  @Prop({}) private parentId: string
  @Prop({}) private isZoom: boolean
  @Prop({}) private unit: string
  @Prop({}) private areaPath: string[]
  private option: AnyObj = {}

  activated() {
    this.update()
  }

  @Watch('value')
  watchValue() {
    this.update()
  }
  private update() {
    const option: any = {
      tooltip: {
        trigger: 'axis',
        borderWidth: 0,
        backgroundColor: [variables.backgroundColorPopper],
        extraCssText: `box-shadow:0px 0px 10px 0px ${variables.backgroundColorPopper};`,
        axisPointer: {
          type: 'shadow',
          shadowStyle: {
            color: [variables.shadow9Color],
            shadowBlur: 12,
            shadowColor: [variables.transparent]
          }
        },
        formatter: (params: AnyObj[]) => {
          let result = ''
          let p = ''
          if (params.length > 1) {
            params.forEach(item => {
              result =
                result +
                '<div><span style="width:8px;height:8px;display:inline-block;margin-right:5px;background:' +
                item.color +
                '"></span><p style="width:50px;margin:0;display:inline-block">' +
                item.seriesName +
                `</p><span style="color:${variables.colorPrimary};margin-left:10px">` +
                item.value +
                '</span></div>'
            })
          } else {
            result =
              '<p>' +
              this.unit +
              `<span style="color:${variables.colorPrimary};margin-left:10px">` +
              params[0].value +
              '</span></p>'
          }
          if (this.areaPath !== undefined) {
            p =
              `<p style="border-bottom:1px solid ${variables.backgroundColorMenu};text-align:center">` +
              this.areaPath[params[0].dataIndex] +
              '</p>'
          } else {
            p =
              `<p style="border-bottom:1px solid ${variables.backgroundColorMenu};text-align:center">` +
              params[0].name +
              '</p>'
          }
          const temp =
            `<div style="font-size:16px;color:${variables.textColorRegular};padding:8px;">` +
            p +
            result +
            '</div>'
          return temp
        }
      },
      grid: {
        left: '3%', //图表距边框的距离
        right: '4%',
        bottom: '12%',
        top: '8%',
        containLabel: true
      },
      color: [
        [variables.orangeColor],
        [variables.colorPrimary],
        [variables.whiteTagColor],
        [variables.blue12Color],
        [variables.yellowColor],
        [variables.blue13Color]
      ],
      textStyle: {
        fontSize: 13,
        color: [variables.echartLine4Color]
      },
      xAxis: {
        data: this.name,
        boundaryGap: true,
        axisLine: {
          //axisLine决定是否显示坐标刻度
          show: false
        },
        axisLabel: {
          interval: 0,
          formatter: function (value: string) {
            let res = ''
            if (value !== undefined && value !== null) {
              if (value.indexOf(' ') > -1) {
                const day = value.split(' ')[0]
                const hour = value.split(' ')[1]
                return day + '\n' + hour
              } else {
                const maxLength = 6 //每项显示文字个数
                const valLength = value.length //X轴类目项的文字个数
                const rowNumber = Math.ceil(valLength / maxLength) //类目项需要换行的行数
                if (rowNumber > 1) {
                  for (let i = 0; i < rowNumber; i++) {
                    let temp = '' //每次截取的字符串
                    const start = i * maxLength //开始截取的位置
                    const end = start + maxLength //结束截取的位置
                    temp = value.substring(start, end) + '\n'
                    res += temp //拼接生成最终的字符串
                  }
                  return res
                } else {
                  return value
                }
              }
            }
          }
        }
      },
      yAxis: {
        axisLine: {
          //axisLine决定是否显示坐标刻度
          show: false
        },
        splitLine: { lineStyle: { type: 'dotted', color: [variables.dividerColor] } }
      },
      series: this.value
    }
    if (this.isZoom) {
      const width = document.getElementsByClassName(this.parentId)[0].clientWidth
      const value = Math.floor(width / 90)
      option.dataZoom = [
        {
          type: 'slider',
          show: true,
          realtime: true,
          startValue: 0,
          endValue: value,
          backgroundColor: variables.backgroundColorPopper, // 组件的背景颜色
          fillerColor: variables.colorPrimary, // 选中范围的填充颜色。
          bottom: '0',
          zoomLock: true,
          height: 8,
          xAxisIndex: [0],
          handleStyle: {
            show: false,
            opacity: 0
          },
          textStyle: {
            color: 'rgba(0, 0, 0, 0)'
          },
          borderColor: [variables.popBgColor]
        }
      ]
    }
    this.option = option
  }
}
