<script>
import ECharts from '@/components/common/Echarts/echart'
import { generate, variables } from '@/libs/theme'
import treeMap from '@/libs/operation-tree-node/treeMap'
const color = [
  '#5470c6',
  '#91cc75',
  '#fac858',
  '#ee6666',
  '#73c0de',
  '#3ba272',
  '#fc8452',
  '#9a60b4',
  '#ea7ccc'
]
export default {
  data() {
    return {
      option: {}
    }
  },
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  activated() {
    this.update()
  },
  methods: {
    subName(value) {
      value.forEach(obj => {
        obj.tipName = obj.name
        let str = ''
        const maxLength = 5
        const valLength = obj.name.length
        if (valLength > maxLength) {
          const rowNumber = Math.ceil(valLength / maxLength)
          for (let i = 0; i < rowNumber; i++) {
            let temp = ''
            const start = i * maxLength
            const end = start + maxLength
            temp = obj.name.substring(start, end) + '\n'
            str += temp
          }
          obj.name = str
        }
        if (obj.children) {
          this.subName(obj.children)
        }
      })
      return value
    },
    async update() {
      const res = _.cloneDeep(this.value)
      const lastValue = this.subName(res)
      const root = lastValue[0]

      let count = 3
      let colorList = []
      let flag = false
      const data = treeMap(lastValue, (node, index, _, parent) => {
        if (node.id === root.id) {
          return {
            ...node,
            itemStyle: { color: variables.colorPrimary }
          }
        } else if (parent?.id === root?.id) {
          count = 3
          colorList = generate(color[index % color.length], {
            theme: 'dark'
          }).reverse()
          return { ...node, itemStyle: { color: colorList[count] } }
        } else {
          if (flag || count === 9) count = 3
          count++
          flag = node.children ? false : true
          return { ...node, itemStyle: { color: colorList[count] } }
        }
      })
      const option = {
        tooltip: {
          trigger: 'item', //坐标轴触发，可设为 item 数据项图形触发
          borderWidth: 0,
          backgroundColor: [variables.backgroundColorPopper],
          textStyle: { color: variables.textColorRegular },
          formatter: function (params) {
            let title = ''
            let value = 0
            let type = 'value'
            if (params.data.times !== undefined) {
              title = '到访次数'
              value = params.data.frequency
            } else if (params.data.duration !== undefined) {
              title = '停留时间'
              value = params.data.stayTime
            } else if (params.data.avgStayTime !== undefined) {
              title = '平均停留时间'
              value = params.data.avgStayTime
            } else {
              type = 'noValue'
            }
            const str =
              '<div style="padding: 0 8px"><p style="width:100px;margin:0;display:inline-block">' +
              title +
              `</p><span style="color:${variables.colorPrimary};margin-left:10px">` +
              value +
              '</span></div>'
            const temp =
              `<div style="font-size:16px;color:${variables.textColorRegular};padding:8px 0;text-align:left"><p style="border-bottom:1px solid ${variables.backgroundColorMenu};padding: 0 8px 8px;text-align:center">` +
              params.data.tipName +
              '</p>' +
              str +
              '</div>'
            return type === 'noValue' ? '' : temp
          }
        },
        series: {
          type: 'sunburst',
          highlightPolicy: 'self',
          data: data,
          radius: ['10%', '90%'],
          center: ['50%', '50%'],
          label: {
            rotate: 'radial',
            align: 'center',
            fontSize: 12,
            fontWeight: 'normal',
            color: [variables.whiteColor],
            minAngle: 8
          },
          itemStyle: {
            borderColor: variables.backgroundColorBase,
            borderWidth: 2
          },
          sort: null,
          levels: [
            {
              itemStyle: {
                color: [variables.backgroundColorBaseDark]
              },
              r0: '0',
              r: '1%'
            },
            {
              itemStyle: {
                color: variables.colorPrimary
              }
            }
          ]
        }
      }
      this.option = option
    }
  },
  watch: {
    value() {
      this.update()
    }
  },
  components: {
    ECharts
  }
}
</script>
<template>
  <div>
    <e-charts :option="option" :auto_load="true"></e-charts>
    <slot />
  </div>
</template>
