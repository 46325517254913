<script>
import ECharts from '@/components/common/Echarts/echart'
import { variables } from '@/libs/theme'
export default {
  data() {
    return {
      option: {}
    }
  },
  props: {
    name: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      required: true
    },
    parentId: {
      type: String,
      required: true
    }
  },
  activated() {
    this.update()
  },
  methods: {
    update() {
      this.value.forEach(item => {
        item.type = 'line'
        item.smooth = true
        item.showSymbol = false
        item.symbol = 'circle'
        item.symbolSize = 8
      })
      const option = {
        textStyle: {
          fontSize: 13,
          color: [variables.echartLine4Color]
        },
        grid: {
          left: '3%', //图表距边框的距离
          right: '3%',
          bottom: '3%',
          containLabel: true
        },
        color: [[variables.colorPrimary], [variables.echartLine3Color]],
        tooltip: {
          trigger: 'axis', //坐标轴触发，可设为 item 数据项图形触发
          backgroundColor: [variables.backgroundColorPopper],
          extraCssText: `box-shadow:0px 0px 10px 0px ${variables.backgroundColorPopper};`,
          textStyle: { color: variables.textColorRegular },
          borderWidth: 0,
          formatter: function (params) {
            let result = ''
            params.forEach(item => {
              result =
                result +
                '<div style="padding: 0 8px"><span style="width:8px;height:8px;display:inline-block;margin-right:5px;background:' +
                item.color +
                '"></span><p style="width:50px;margin:0;display:inline-block">' +
                item.seriesName +
                `</p><span style="color:${variables.colorPrimary};margin-left:10px">` +
                item.value +
                '</span></div>'
            })
            const temp =
              `<div style="font-size:16px;${variables.textColorRegular};padding:8px 0;"><p style="border-bottom:1px solid ${variables.backgroundColorMenu};padding: 0 8px 8px;text-align:center">` +
              params[0].name +
              '</p>' +
              result +
              '</div>'
            return temp
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLine: {
            //axisLine决定是否显示坐标刻度
            show: false
          },
          axisLabel: {
            show: true,
            formatter: function (value) {
              let res = value
              if (value !== undefined && value !== null) {
                if (value.indexOf(' ') > -1) {
                  const day = value.split(' ')[0]
                  const hour = value.split(' ')[1]
                  res = day + '\n' + hour
                }
              }
              return res
            }
          },
          data: this.name
        },
        yAxis: {
          type: 'value',
          splitLine: { lineStyle: { type: 'dotted', color: [variables.dividerColor] } },
          axisLine: { show: false }
        },
        series: this.value
      }
      this.option = option
    }
  },
  watch: {
    value() {
      this.update()
    }
  },
  components: {
    ECharts
  }
}
</script>
<template>
  <div>
    <e-charts :option="option" :auto_load="true" :parentId="parentId"></e-charts>
    <slot />
  </div>
</template>
