











































































































































































































































































































































































































import { Vue, Component } from 'vue-property-decorator'
import SunburstChart from '@/components/common/Echarts/SunburstChart.vue'
import PieChart from '@/components/common/Echarts/PieChart.vue'
import BarGraph from '@/components/common/Echarts/BarGraph.vue'
import BarGraph2 from '@/components/common/Echarts/BarGraph2.vue'
import LineChart from '@/components/common/Echarts/LineChart.vue'
import { formatDateStart, formatDateEnd, getDuration, timeType } from '@/utils'
import { exportStatistic } from '@/utils/export'
import moment from 'moment'
import pageTitle from '@/components/common/pageTitle.vue'
import { noNetwork } from '@/api/axios'
import { variables, rgba } from '@/libs/theme'
import { is } from '@/utils/helpers'
import store, { actionTypes } from '@/store'
import _ from 'lodash'
import treeFind from '@/libs/operation-tree-node/treeFind'
import treeFilter from '@/libs/operation-tree-node/treeFilter'
import treeEach from '@/libs/operation-tree-node/treeEach'

@Component({
  name: 'statistic',
  components: {
    PieChart,
    BarGraph,
    LineChart,
    BarGraph2,
    pageTitle,
    SunburstChart
  }
})
export default class Statistic extends Vue {
  private shadow9Color = variables.shadow9Color
  private noStranger = 0
  private avgStayTime = 0
  private hotArea = '无'
  private statHeight: string | number = '750'
  private lineDataX: any[] = []
  private lineDataY: any[] = []
  private typeList: any[] = []
  private selectList = [
    {
      name: '全部',
      index: 0
    },
    {
      name: '新老客户',
      index: 1
    },
    {
      name: '性别',
      index: 2
    },
    {
      name: '年龄',
      index: 3
    }
  ]
  private tabSelectIndex = 0
  private hoursData: any[] = []
  private messageShow = ''
  private dialogFormVisible = false
  private totalNum = 0
  private passengerFlowNum: any = ''
  private sexDistribution: any = {}
  private ageDistribution: any[] = []
  private customerProportion: any[] = []
  private myBarName: any[] = []
  private noData = false
  private personQuery: any[] = []
  private highestNm = 0
  private highestTime = ''
  private averageResidenceTime = ''
  private noAvgData = false
  private noSumData = false
  private noNetwork = false
  private noDataImg = require('@/assets/images/dashboard/noData.png')
  private noNetworkImg = ''
  private flowSpan = 'hour'
  private filterVal: AnyObj = {
    dateRange: [moment().startOf('day'), moment().endOf('day')],
    isDeduplication: true,
    areaIds: []
  }
  private todayCount = 0
  private todayVip = 0
  private yesterdayCount = 0
  private yesterdayVip = 0
  private sevendayCount = 0
  private sevendayVip = 0
  private yesterdayCountRate: string | number = 0
  private sevendayCountRate: string | number = 0
  private yesterdayVipRate: string | number = 0
  private sevendayVipRate: string | number = 0
  private timeAvgVal: string | number = 0
  private historyAvg: string | number = 0
  private intervalDays = 0
  private historyStartTime = ''
  private isInitial = true
  private touristBarName = [
    '四川',
    '重庆',
    '广东',
    '湖南',
    '浙江',
    '广西',
    '云南',
    '江苏',
    '湖北',
    '北京'
  ]
  private touristBarValue: any[] = []
  private isToDay = true
  private isCrossYear = false
  private passengerCycle = null
  private loading = false
  private tagTypeList = ['strange', 'vip', 'white', 'black']
  private searchPar: any = null
  private exportDialogVisible = false
  private exportLoading = false
  private stayTimeVal: any[] = []
  private stayTimeArr: any[] = []
  private timeType = timeType
  isShowBoard = false
  isShowHighest = false

  get user() {
    return this.$store.state.user || {}
  }
  get rootConfig() {
    return this.$store.state.config?.root
  }
  get root() {
    return this.rootConfig ? this.rootConfig.id : this.$store.state.user.scope
  }
  get isRootAccount() {
    return this.root === this.$store.state.user.scope
  }

  get areaList(): AnyObj[] {
    return this.$store.state.baseAreas
  }

  get startTime() {
    return this.$moment(this.filterVal.dateRange[0]).toDate()
  }

  get endTime() {
    return this.$moment(this.filterVal.dateRange[1]).toDate()
  }

  deactivated() {
    this.exportDialogVisible = false
  }
  async mounted() {
    this.calTableHeight()
    this.noNetworkImg = this.$store.state.dashboard.noNetImg
    this.changeScreen()
    window.addEventListener('resize', this.calTableHeight)
  }
  private async exportResult() {
    this.exportLoading = true
    try {
      if (this.searchPar !== null) {
        delete this.searchPar.isInitialState
        delete this.searchPar.sevenTime
        delete this.searchPar.historyStartTime
        const res = await this.$api.base.statisticExport(this.searchPar)
        exportStatistic(res.data, 'application/zip', 'person_statistics.zip')
      }
      this.exportLoading = false
      this.exportDialogVisible = false
    } catch (e) {
      this.exportLoading = false
      this.exportDialogVisible = false
    }
  }
  private getIcon(val: number) {
    return val >= 0 ? 'icon-shangzhang' : 'icon-xiajiang'
  }
  private getColor(val: number) {
    if (is.num(val)) return val >= 0 ? 'redcolor' : 'greencolor'

    return 'bluecolor'
  }
  private changeSelectTab(tab: AnyObj) {
    this.tabSelectIndex = tab.index
    this.personCount()
    this.setEverySum()
  }
  private getTimes(time: any) {
    // 获取时间戳
    return moment(time, 'YYYY-MM-DD HH:mm:ss').valueOf()
  }
  private rateToFixed(num: number, n = 2) {
    return Math.floor(num * Math.pow(10, n)) / Math.pow(10, n)
  }
  private Calculation(val: any) {
    let res = val
    if (val > 999999999999) {
      res = '9999+亿'
    }
    return res
  }
  private timeTransformation(time: any) {
    const y = time.getFullYear()
    let m = time.getMonth() + 1
    let n = time.getDate()
    if (m < 10) m = '0' + m
    if (n < 10) n = '0' + n
    return y + '-' + m + '-' + n
  }
  private setTimeFormat(StayTime: any) {
    const stay = {
      time: 0,
      stayUnit: '秒'
    }
    switch (true) {
      case StayTime >= 60 * 60 * 24:
        stay.time = StayTime / (60 * 60 * 24)
        stay.stayUnit = '天'
        break
      case StayTime >= 60 * 60:
        stay.time = StayTime / (60 * 60)
        stay.stayUnit = '小时'
        break
      case StayTime >= 60:
        stay.time = StayTime / 60
        stay.stayUnit = '分钟'
        break
      case StayTime < 60:
        stay.time = StayTime
        stay.stayUnit = '秒'
        break
      case StayTime === null:
        stay.time = 0
        stay.stayUnit = '秒'
        break
      default:
        break
    }
    return this.rateToFixed(stay.time, 1) + stay.stayUnit
  }
  private setEverySum() {
    if (this.passengerFlowNum.total) {
      this.totalNum = this.passengerFlowNum.total
      const sum = this.passengerFlowNum
      if (this.tabSelectIndex === 0) {
        this.messageShow = ''
      }
      if (this.tabSelectIndex === 1) {
        this.messageShow =
          '，其中新客户' +
          sum.identification.strange +
          '人，老客户' +
          sum.identification.familiar +
          '人'
      }
      if (this.tabSelectIndex === 2) {
        this.messageShow = '，其中男' + sum.gender.male + '人，女' + sum.gender.female + '人'
      }
      if (this.tabSelectIndex === 3) {
        this.messageShow =
          '，其中 (0-17) ' +
          sum.age.child +
          '人，(18-25) ' +
          sum.age.teen +
          '人，(26-35) ' +
          sum.age.youth +
          '人，(36-45) ' +
          sum.age.adult +
          '人，(46-55) ' +
          sum.age.midlife +
          '人，(56以上) ' +
          sum.age.old +
          '人'
      }
    } else {
      this.totalNum = 0
      this.messageShow = ''
    }
  }
  //某段时间的按天统计(老/新，性别，年龄)
  private dateSelectHandel() {
    const statistic = this.passengerFlowNum
    if (statistic.total !== 0) {
      this.noSumData = false
      //新老客户占比
      const strange = Number(statistic.identification.strange)
      const familiar = Number(statistic.identification.familiar)
      const total = strange + familiar
      const strangeVal = ((strange / total) * 100).toFixed(1)
      this.customerProportion = [
        { name: '新客户', value: strange, percent: strangeVal },
        { name: '老客户', value: familiar, percent: (100 - parseFloat(strangeVal)).toFixed(1) }
      ]
      //性别分布
      const male = Number(statistic.gender.male)
      const female = Number(statistic.gender.female)
      const personSum = male + female
      const femaleVal = ((female / personSum) * 100).toFixed(1)
      const maleVal = (100 - parseFloat(femaleVal)).toFixed(1)
      this.sexDistribution = {
        data: [
          { name: '女', value: statistic.gender.female },
          { name: '男', value: statistic.gender.male }
        ],
        Percent: [femaleVal, maleVal]
      }
      //年龄分布
      this.myBarName = ['0-17', '18-25', '26-35', '36-45', '46-55', '56以上']
      const ageDate = [
        statistic.age.child,
        statistic.age.teen,
        statistic.age.youth,
        statistic.age.adult,
        statistic.age.midlife,
        statistic.age.old
      ]
      this.ageDistribution = [
        {
          name: '',
          type: 'bar',
          data: ageDate,
          barWidth: 18,
          itemStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: [variables.echartBar1Color] },
                { offset: 1, color: [variables.echartLine2Color] }
              ]),
              barBorderRadius: [10, 10, 0, 0],
              label: {
                show: true,
                position: 'top',
                textStyle: { color: [variables.colorPrimary], fontSize: 16 }
              }
            }
          }
        }
      ]
    } else {
      this.noSumData = true
    }
  }
  private formatTime(time: any) {
    //return moment(time).format('MM/DD/HH');
    let str
    const y = time.getFullYear()
    const m = time.getMonth() + 1
    const n = time.getDate()
    const h = time.getHours()
    const month = m < 10 ? '0' + m : m
    const day = n < 10 ? '0' + n : n
    if (this.intervalDays < 8) {
      if (this.isCrossYear) {
        str = y + '-' + month + '-' + day + ' ' + h + ':00'
      } else {
        str = month + '-' + day + ' ' + h + ':00'
      }
    } else {
      if (this.isCrossYear) {
        str = y + '-' + month + '-' + day + ' '
      } else {
        str = month + '-' + day
      }
    }
    return str
  }
  private personCount() {
    if (this.personQuery.length > 0) {
      this.noData = false
      this.lineDataX = this.personQuery.map(i => {
        return this.formatTime(new Date(i.time))
      })
      if (this.tabSelectIndex === 0) {
        this.typeList = []
        const total = this.personQuery.map(i => i.total)
        this.lineDataY = [
          {
            data: total,
            name: '客流量',
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  { offset: 0, color: [variables.colorPrimary] },
                  { offset: 1, color: [variables.colorPrimary] }
                ]),
                borderColor: [variables.whiteColor],
                borderWidth: 3
              }
            },
            lineStyle: {
              normal: {
                width: 4,
                color: {
                  type: 'linear',
                  colorStops: [
                    { offset: 0, color: [variables.colorPrimary] },
                    { offset: 1, color: [variables.echartLine3Color] }
                  ],
                  globalCoord: false // 缺省为 false
                },
                shadowColor: [variables.popBgColor],
                shadowBlur: 12,
                shadowOffsetY: 6
              }
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    { offset: 0, color: [variables.transparent] },
                    { offset: 0.7, color: [rgba(variables.colorPrimary, 0.14)] }
                  ],
                  false
                )
              }
            }
          }
        ]
      } else if (this.tabSelectIndex === 1) {
        this.typeList = [
          { value: '新客户', color: [variables.colorPrimary] },
          { value: '老客户', color: [variables.whiteTagColor] }
        ]
        const oldValue = this.personQuery.map(i => i.identification.familiar)
        const newValue = this.personQuery.map(i => i.identification.strange)
        const newArr = [
          {
            name: '新客户',
            data: newValue,
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    { offset: 0, color: [rgba(variables.colorPrimary, 0.5)] },
                    { offset: 0.7, color: [rgba(variables.colorPrimary, 0.14)] }
                  ],
                  false
                ),
                shadowColor: [variables.blue4Color],
                shadowBlur: 10
              }
            },
            itemStyle: { normal: { borderColor: [variables.whiteColor], borderWidth: 3 } },
            lineStyle: {
              normal: {
                width: 3,
                shadowColor: [variables.popBgColor],
                shadowBlur: 12,
                shadowOffsetY: 6
              }
            }
          },
          {
            name: '老客户',
            data: oldValue,
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    { offset: 0, color: [variables.blue3Color] },
                    { offset: 0.7, color: [variables.blue4Color] }
                  ],
                  false
                ),
                shadowColor: [variables.blue4Color],
                shadowBlur: 10
              }
            },
            itemStyle: { normal: { borderColor: [variables.whiteColor], borderWidth: 3 } },
            lineStyle: {
              normal: {
                width: 3,
                shadowColor: [variables.popBgColor],
                shadowBlur: 12,
                shadowOffsetY: 6
              }
            }
          }
        ]
        this.lineDataY = newArr
      } else if (this.tabSelectIndex === 2) {
        this.typeList = [
          { value: '男', color: [variables.colorPrimary] },
          { value: '女', color: [variables.whiteTagColor] }
        ]
        const male = this.personQuery.map(i => i.gender.male)
        const female = this.personQuery.map(i => i.gender.female)
        const newArr = [
          {
            name: '男',
            data: male,
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    { offset: 0, color: [variables.transparent] },
                    { offset: 0.7, color: [variables.green9Color] }
                  ],
                  false
                ),
                shadowColor: [variables.blue4Color],
                shadowBlur: 10
              }
            },
            itemStyle: { normal: { borderColor: [variables.whiteColor], borderWidth: 3 } },
            lineStyle: {
              normal: {
                width: 3,
                shadowColor: [variables.popBgColor],
                shadowBlur: 12,
                shadowOffsetY: 6
              }
            }
          },
          {
            name: '女',
            data: female,
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    { offset: 0, color: [variables.blue3Color] },
                    { offset: 0.7, color: [variables.blue4Color] }
                  ],
                  false
                ),
                shadowColor: [variables.blue4Color],
                shadowBlur: 10
              }
            },
            itemStyle: { normal: { borderColor: [variables.whiteColor], borderWidth: 3 } },
            lineStyle: {
              normal: {
                width: 4,
                shadowColor: [variables.popBgColor],
                shadowBlur: 12,
                shadowOffsetY: 6
              }
            }
          }
        ]
        this.lineDataY = newArr
      } else if (this.tabSelectIndex === 3) {
        this.typeList = [
          { value: '0～17', color: [variables.orangeColor] },
          { value: '18～25', color: [variables.colorPrimary] },
          { value: '26～35', color: [variables.whiteTagColor] },
          { value: '36～45', color: [variables.blue12Color] },
          { value: '46～55', color: [variables.yellowColor] },
          { value: '56以上', color: [variables.blue13Color] }
        ]
        const age0 = this.personQuery.map(i => i.age.child)
        const age18 = this.personQuery.map(i => i.age.teen)
        const age26 = this.personQuery.map(i => i.age.youth)
        const age36 = this.personQuery.map(i => i.age.adult)
        const age46 = this.personQuery.map(i => i.age.midlife)
        const age56 = this.personQuery.map(i => i.age.old)
        const newArr: any = [
          { name: '0-17', data: age0 },
          { name: '18-25', data: age18 },
          { name: '26-35', data: age26 },
          { name: '36-45', data: age36 },
          { name: '46-55', data: age46 },
          { name: '56以上', data: age56 }
        ]
        for (let i = 0; i < newArr.length; i++) {
          newArr[i].type = 'bar'
          newArr[i].stack = '年龄'
          newArr[i].barWidth = 17
        }
        this.hoursData = newArr
      }
    } else {
      this.noData = true
    }
  }
  private changeScreen() {
    this.isInitial = this.isInitialState(this.filterVal)
    this.intervalDays = parseInt(
      (
        (this.getTimes(this.endTime) - this.getTimes(this.startTime)) / (24 * 60 * 60 * 1000) +
        1
      ).toString()
    )
    this.isCrossYear = this.startTime.getFullYear() !== this.endTime.getFullYear()
    this.getDayNum()
  }
  private delKey(obj: AnyObj, ...args: any) {
    args.forEach((v: any) => {
      delete obj[v]
    })
    return obj
  }
  private getDuration(seconds: number, type: any) {
    let res = '0'
    if (seconds === undefined) {
      res = 0 + '秒'
    } else {
      res = getDuration(seconds, type) || ''
    }
    return res
  }
  private getAreaVal(arr: any[], val: any[], checkIds: any[]) {
    for (let i = 0, flag = true, len = arr.length; i < len; flag ? i++ : i) {
      if (arr[i] !== undefined) {
        const res = val.filter(m => m.areaId === arr[i].id)
        // 判断arr[i]是否属于所筛选的checkIds
        const isIncludes = checkIds.includes(arr[i].id)
        if (isIncludes && res.length > 0 && res[0].personCount !== 0) {
          arr[i].avgDuration = res[0].timeSum / res[0].personCount
          arr[i].avgStayTime = getDuration(
            res[0].timeSum / res[0].personCount,
            timeType.TWO_SECTIONS
          )
          this.delKey(
            arr[i],
            'description',
            'capacity',
            'mapId',
            'mapLabel',
            'createdAt',
            'updatedAt'
          )

          if (arr[i].children) {
            this.getAreaVal(arr[i].children, val, checkIds)
          }
          flag = true
        } else {
          arr.splice(i, 1)
          flag = false
        }
      } else {
        flag = true
      }
    }
    return arr
  }
  private async stayTime() {
    const val: any = this.averageResidenceTime
    const num = val.filter((i: AnyObj) => i.personCount !== 0)
    if (num.length > 0) {
      this.noAvgData = false
      const areas = await store.dispatch(actionTypes.FETCH_BASE_AREAS)
      if (areas) {
        const areaIds =
          this.filterVal.areaIds && this.filterVal.areaIds.length > 0
            ? this.filterVal.areaIds
            : [this.root]
        let allAreaList: any[] = []
        areaIds.forEach((item: any) => {
          if (!allAreaList.includes(item)) {
            const filter = treeFilter(areas, (node: any) => node.id === item)
            const find = treeFind(areas, (node: any) => node.id === item)
            filter.push(find)
            treeEach(filter, node => {
              allAreaList.push(node)
            })
            allAreaList = allAreaList.concat(filter)
          }
        })
        const checkIds = Array.from(new Set(allAreaList.map(i => i.id)))

        const resp = this.getAreaVal(_.cloneDeep(areas), val, checkIds)
        this.stayTimeVal = this.assignment(resp)
      }
    } else {
      this.noAvgData = true
    }
  }
  private assignment(val: AnyObj[], type?: string) {
    val.forEach(obj => {
      if (obj.children && obj.children.length > 0) {
        this.assignment(obj.children, type)
      } else {
        obj.value = obj.avgDuration
      }
    })
    return val
  }
  private setTime(time?: any) {
    return moment(time).format('YYYY/MM/DD')
  }
  private setPassenger(res: any) {
    const sevenDays = this.setTime(formatDateStart(moment(this.startTime).add(-7, 'd')))
    const yesterday = this.setTime(formatDateStart(moment(this.startTime).add(-1, 'd')))
    if (this.setTime(this.endTime) === this.setTime()) {
      this.isToDay = true
    } else {
      this.isToDay = false
    }
    if (res.sevenDayCount && res.sevenDayCount.length > 0) {
      res.sevenDayCount.forEach((obj: AnyObj) => {
        const ts = this.setTime(obj.time)
        if (ts === this.setTime(this.endTime)) {
          //今日数据
          this.todayCount = obj.total
        }
        if (ts === yesterday) {
          this.yesterdayCount = obj.total
        }
        if (ts === sevenDays) {
          this.sevendayCount = obj.total
        }
      })
      this.yesterdayCountRate = this.customerRate(this.todayCount, this.yesterdayCount)
      this.sevendayCountRate = this.customerRate(this.todayCount, this.sevendayCount)
    } else {
      // 接口没有数据时显示0，不显示之前的数据
      this.yesterdayCount = 0
      this.sevendayCount = 0
      this.yesterdayCountRate = this.customerRate(0, 0)
      this.sevendayCountRate = this.customerRate(0, 0)
    }
    if (res.sevenVipCount && res.sevenVipCount.length > 0) {
      res.sevenVipCount.forEach((obj: AnyObj) => {
        const ts = this.setTime(obj.time)
        if (ts === this.setTime(this.endTime)) {
          //今日数据
          this.todayVip = obj.total
        }
        if (ts === yesterday) {
          this.yesterdayVip = obj.total
        }
        if (ts === sevenDays) {
          this.sevendayVip = obj.total
        }
      })
      this.yesterdayVipRate = this.customerRate(this.todayVip, this.yesterdayVip)
      this.sevendayVipRate = this.customerRate(this.todayVip, this.sevendayVip)
    }
  }
  private customerRate(todayData: any, data: any) {
    if (data === 0) return '--'
    return this.rateToFixed(((todayData - data) / data) * 100, 1)
  }
  private judgeResultFun(arr1: any, arr2: any) {
    let flag = true
    if (arr1.length !== arr2.length) {
      flag = false
    } else {
      arr1.forEach((item: string) => {
        if (arr2.indexOf(item) === -1) {
          flag = false
        }
      })
    }
    return flag
  }
  private isInitialState(val: AnyObj) {
    const { areaIds } = val

    if (!areaIds) return true
    if (areaIds.length === 0 || (areaIds.length === 1 && areaIds[0] === this.root)) return true

    return false
  }
  //日期选择按天/按小时查客流量 / （新/老，年龄，性别）求总和 /历史最高 /平均停留时间
  async getDayNum() {
    this.loading = true
    const val = this.filterVal
    const identity = val.identity
    const areaId = this.root
    const areas = await store.dispatch(actionTypes.FETCH_BASE_AREAS)
    const allAreaList: any[] = []
    treeEach(areas, (node: any) => {
      if (!node.children) {
        allAreaList.push(node)
      }
    })
    const checkIds = Array.from(new Set(allAreaList.map(i => i.id)))
    const par: any = {
      startTime: formatDateStart(this.startTime),
      endTime: formatDateEnd(this.endTime),
      areaIds:
        val.areaIds && val.areaIds.length > 0
          ? val.areaIds
          : this.filterVal.isDeduplication
          ? [areaId]
          : checkIds,
      span: this.intervalDays < 8 ? 'hour' : 'day',
      identity
    }
    if (this.intervalDays > 1) {
      await this.getHistoryTime(val)
      Object.assign(par, { historyStartTime: formatDateStart(this.historyStartTime) })
    } else {
      Object.assign(par, {
        isInitialState: this.isInitialState(this.filterVal),
        sevenTime: formatDateStart(moment(this.startTime).add(-7, 'd').valueOf())
      })
    }
    this.searchPar = par
    try {
      const { data } = await this.$api.base.getStatisticsDayCount(par)
      this.loading = false
      if (data.code == 0) {
        const resp = data.data
        this.noNetwork = noNetwork
        // 人数统计
        this.personQuery = resp.personCount
        this.personCount()
        this.averageResidenceTime = resp.residenceTime
        this.stayTime() // 平均停留时间
        if (resp.board) {
          this.isShowBoard = true
          this.hotArea = resp.board.hotArea ? resp.board.hotArea.areaName : '无' // 最热区域
          this.avgStayTime = resp.board.avgStayTime || 0 // 平均停留时间
          this.noStranger = resp.board.noStranger || 0 // 非陌生人
        } else {
          this.isShowBoard = false
        }
        // 是否显示历史最高
        this.isShowHighest = this.filterVal.isDeduplication
          ? this.filterVal.areaIds.length <= 1
          : this.filterVal.areaIds.length === 1

        this.passengerFlowNum = resp.daySum
        if (resp.passengerFlow !== null) {
          // 客流量
          const flow = resp.passengerFlow
          if (this.intervalDays > 1) {
            this.timeAvgVal = (this.passengerFlowNum.total / this.intervalDays).toFixed(0) // 期间平均客流量
            this.historyAvg = flow.historyAvg ? flow.historyAvg.toFixed(0) : 0 // 历史平均客流量
          } else {
            if (flow.historyMax) {
              // 历史最高
              this.highestNm = flow.historyMax.total
              if (flow.historyMax.time !== '' && flow.historyMax.time !== undefined) {
                this.highestTime = this.timeTransformation(new Date(flow.historyMax.time))
              } else {
                this.highestTime = ''
              }
            } else {
              this.highestNm = 0
              this.highestTime = ''
            }
          }
          this.setPassenger(resp.passengerFlow) // 昨天和上周同日数据
        }
        // 按选择日期的新老，年龄，性别
        this.setEverySum()
        if (this.user.id === 42 && this.user.username === 'dali_shuanglang') {
          this.getTouristSource()
        }
        setTimeout(() => {
          this.dateSelectHandel()
        }, 0)
      }
    } catch (err) {
      this.loading = false
    }
  }
  private async getHistoryTime(val: any) {
    // 所选区域的最晚创建时间
    if (val !== null) {
      if (val.areaIds && val.areaIds.length > 0) {
        const time = treeFilter(this.areaList, node => val.areaIds.includes(node.id)).map(
          i => i.createdAt
        )
        time.sort(function (a, b) {
          return a - b
        })
        this.historyStartTime = time[0]
      } else {
        this.historyStartTime = this.areaList.find(i => {
          return i.id === this.root
        })?.createdAt
      }
    }
  }
  private calTableHeight() {
    this.$nextTick(() => {
      this.statHeight = window.innerHeight - 48
    })
  }
  private getTouristSource() {
    const proportion = [
      '0.191094',
      '0.177706',
      '0.089741',
      '0.072776',
      '0.071235',
      '0.063953',
      '0.063082',
      '0.050482',
      '0.046071',
      '0.045859'
    ]
    const BarValue = proportion.map(i => {
      return (Number(i) * this.passengerFlowNum.total).toFixed(0)
    })
    this.touristBarValue = [
      {
        name: '',
        type: 'bar',
        data: BarValue,
        barWidth: 18,
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: [variables.echartLine1Color] },
              { offset: 1, color: [variables.echartLine2Color] }
            ]),
            barBorderRadius: [10, 10, 0, 0],
            label: {
              show: true,
              position: 'top',
              textStyle: { color: [variables.colorPrimary], fontSize: 16 }
            }
          }
        }
      }
    ]
  }
  destroyed() {
    window.removeEventListener('resize', this.calTableHeight)
  }
}
