
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import ECharts from '@/components/common/Echarts/echart.vue'
import { variables } from '@/libs/theme'

@Component({
  name: 'barGraph2',
  components: {
    ECharts
  }
})
export default class BarGraph2 extends Vue {
  @Prop({}) private name: string[]
  @Prop({}) private value: AnyObj
  @Prop({}) private parentId: string
  @Prop({}) private isZoom: boolean
  private option: AnyObj = {}

  activated() {
    this.update()
  }

  @Watch('value')
  watchValue() {
    this.update()
  }
  private update() {
    const sexIcons = {
      male: '/static/img/male.png',
      female: '/static/img/female.png'
    }
    const option = {
      tooltip: {
        trigger: 'axis',
        borderWidth: 0,
        backgroundColor: [variables.backgroundColorPopper],
        extraCssText: `box-shadow:0px 0px 10px 0px ${variables.backgroundColorPopper};`,
        axisPointer: {
          type: 'shadow',
          shadowStyle: {
            color: [variables.grey73Color],
            shadowBlur: 12,
            shadowColor: [variables.transparent]
          }
        },
        formatter: (params: AnyObj[]) => {
          const res =
            `<div style="font-size:16px;color:${variables.textColorRegular};padding:8px;"><p style="border-bottom:1px solid ${variables.backgroundColorMenu};text-align:center">` +
            this.value.data[params[0].dataIndex].name +
            `</p><p>人数<span style="color:${variables.colorPrimary};margin-left:10px">` +
            this.value.data[params[0].dataIndex].value +
            `</span></p><p>比例<span style="color:${variables.colorPrimary};margin-left:10px">` +
            params[0].value +
            '%</span></p></div>'
          return res
        }
      },
      grid: {
        left: 60,
        right: 60
      },
      xAxis: {
        axisLabel: {
          textStyle: {
            color: [variables.echartLine4Color]
          },
          formatter: '{value}%'
        },
        axisLine: {
          lineStyle: {
            color: [variables.dividerColor]
          }
        },
        splitLine: { lineStyle: { type: 'dotted', color: [variables.dividerColor] } }
      },
      yAxis: {
        type: 'category',
        inverse: true,
        data: ['female', 'male'],
        axisLine: {
          lineStyle: {
            type: 'dotted',
            color: [variables.dividerColor]
          }
        },
        axisLabel: {
          formatter: function (value: string) {
            return '{' + value + '| }'
          },
          margin: 20,
          rich: {
            value: {
              lineHeight: 30,
              align: 'center'
            },
            male: {
              height: 20,
              align: 'center',
              backgroundColor: {
                image: sexIcons.male
              }
            },
            female: {
              height: 20,
              align: 'center',
              backgroundColor: {
                image: sexIcons.female
              }
            }
          }
        }
      },
      color: [
        new echarts.graphic.LinearGradient(0, 0, 1, 0, [
          {
            offset: 0,
            color: [variables.echartBar1Color]
          },
          {
            offset: 1,
            color: [variables.echartLine2Color]
          }
        ])
      ],
      series: [
        {
          name: '',
          type: 'bar',
          data: this.value.Percent,
          barWidth: 18,
          itemStyle: {
            normal: {
              barBorderRadius: [0, 10, 10, 0],
              label: {
                show: true, //开启显示
                position: 'right', //在上方显示
                textStyle: {
                  //数值样式
                  color: [variables.colorPrimary],
                  fontSize: 16
                },
                formatter: function (params: AnyObj) {
                  return Number(params.data).toFixed(1) + '%'
                }
              }
            }
          }
        }
      ]
    }
    this.option = option
  }
}
