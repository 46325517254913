import _ from 'lodash'
export default {
  data() {
    return {
      sidebarElm: null,
      resizeHandler: null
    }
  },
  mounted() {
    this.initListener()
  },
  activated() {
    if (!this.resizeHandler) {
      this.initListener()
    }
    this.resize()
  },
  beforeDestroy() {
    this.destroyListener()
  },
  deactivated() {
    this.destroyListener()
  },
  methods: {
    sidebarResizeHandler(e) {
      if (e.propertyName === 'width') {
        this.resizeHandler()
      }
    },
    initListener() {
      this.resizeHandler = _.debounce(this.resize, 100)
      window.addEventListener('resize', this.resizeHandler)

      this.sidebarElm = document.getElementsByClassName('aside')[0]
      this.sidebarElm &&
        this.sidebarElm.addEventListener('transitionend', this.sidebarResizeHandler)
    },
    destroyListener() {
      window.removeEventListener('resize', this.resizeHandler)
      this.resizeHandler = null
      this.sidebarElm &&
        this.sidebarElm.removeEventListener('transitionend', this.sidebarResizeHandler)
    },
    resize() {
      const { chart } = this
      chart && chart.resize()
    }
  }
}
